export const adminInitialState = {
  isLoggedIn: false,
  accessToken: null,
  userDetails: null
};

export const usersInitialState = {
  isLoading: true,
  value: [],
  index: 0,
  limit: 10,
  totalCount: 0,
  search: "",
  filters: {
    approved: null,
    deleted: null
  }
};

export const communitiesInitialState = {
  isLoading: true,
  value: [],
  index: 0,
  limit: 10,
  totalCount: 0,
  search: ""
};

export const postsInitialState = {
  isLoading: true,
  value: [],
  filters: {
    fromDate: "",
    toDate: "",
    approved: null,
    reported: false
  },
  index: 0,
  limit: 12,
  totalCount: 0
};

export const jobsInitialState = {
  isLoading: true,
  value: [],
  filters: {
    fromDate: "",
    toDate: ""
  },
  index: 0,
  limit: 10,
  totalCount: 0,
  search: ""
};

export const rentalsInitialState = {
  isLoading: true,
  value: [],
  filters: {
    fromDate: "",
    toDate: ""
  },
  index: 0,
  limit: 10,
  totalCount: 0,
  search: ""
};
export const businessInitialState = {
  isLoading: true,
  value: [],
  fromDate: "",
  toDate: "",
  index: 0,
  limit: 10,
  totalCount: 0
};

export const combinedInitialState = {
  admin: adminInitialState,
  users: usersInitialState,
  communities: communitiesInitialState,
  posts: postsInitialState,
  jobs: jobsInitialState
};

export const stateToBePersisted = ["admin"];
