import React from "react";
import PropTypes from "prop-types";

const LoadingComponent = (props) => {
  const { customStyle } = props;
  return (
    <i
      className='fas fa-spinner fa-spin'
      style={{
        color: "#000",
        fontSize: "2rem",
        ...customStyle,
      }}
    ></i>
  );
};

LoadingComponent.propTypes = {
  customStyle: PropTypes.object,
};

export default LoadingComponent;
