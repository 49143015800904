import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAosMj0La9GyuIPCynju4nXOtKKWG5hRbo",
  authDomain: "podiem-9bd90.firebaseapp.com",
  projectId: "podiem-9bd90",
  storageBucket: "podiem-9bd90.appspot.com",
  messagingSenderId: "298926380354",
  appId: "1:298926380354:web:7c24c2988409880a8b24db",
  measurementId: "G-N2N7C7M4KY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
