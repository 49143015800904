import React, { Suspense } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import ProtectedRoute from "./utils/ProtectedRoute";
import LoadingComponent from "./components/LoadingComponent";

const LoadingWindow = styled.div`
  height: calc(100vh - 100px);
  display: grid;
  place-items: center;
`;

const loaderStyle = {
  fontSize: "3rem"
};

// Route protection for autenticated user
const ProtectedRouteWithSuspense = (props) => {
  const { children } = props;
  return (
    <Suspense
      fallback={
        <LoadingWindow>
          <LoadingComponent customStyle={loaderStyle} />
        </LoadingWindow>
      }
    >
      <ProtectedRoute>{children}</ProtectedRoute>
    </Suspense>
  );
};

ProtectedRouteWithSuspense.propTypes = {
  children: PropTypes.element.isRequired
};

export default ProtectedRouteWithSuspense;
