import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jobsInitialState } from "./states";
import axios from "axios";

const initialState = jobsInitialState;

export const fetchJobsAsync = createAsyncThunk(
  "jobs/async",
  async (input, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { getState, rejectWithValue } = thunkApi;
    const { admin, jobs } = getState();
    const config = {
      headers: {
        authorization: `Bearer ${admin.accessToken}`
      }
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}admin/getAllJobs?startDate=${jobs?.filters?.fromDate}&endDate=${jobs?.filters?.toDate}&index=${jobs?.index}&limit=${jobs.limit}&search=${jobs.search}`,
        config
      );
      if (successCallback && typeof successCallback === "function") {
        successCallback();
      }
      return {
        jobs: response?.data?.content,
        totalCount: response?.data?.totalJobs
      };
    } catch (error) {
      errorCallback(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const JobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    verified: (state, action) => {
      const { id, newData } = action.payload;
      const index = state.value.findIndex((jobs) => jobs._id === id);
      if (index !== -1) {
        state.value[index] = {
          ...state.value[index],
          ...newData
        };
      }
    },
    setToDate: (state, action) => {
      state.filters.toDate = action.payload;
    },
    setFromDate: (state, action) => {
      state.filters.fromDate = action.payload;
    },
    clearFilter: (state) => {
      state.filters.fromDate = "";
      state.filters.toDate = "";
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setSearchText: (state, action) => {
      state.search = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobsAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchJobsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action?.payload?.jobs;
      state.totalCount = action?.payload?.totalCount;
    });
    builder.addCase(fetchJobsAsync.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export default JobsSlice.reducer;
export const {
  verified,
  setToDate,
  setFromDate,
  clearFilter,
  setLimit,
  setIndex,
  setSearchText
} = JobsSlice.actions;
