export const AddUserPersistance = (value) => {
  try {
    const user = { user: value };
    window.localStorage.setItem("redux-persist", JSON.stringify(user));
  } catch (error) {
    console.log(error);
  }
};

export const removeUserPersistace = () => {
  try {
    window.localStorage.clear("redux-persist");
  } catch (error) {
    console.log(error);
  }
};
