import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./utils/redux/adminSlice";
import usersReducer from "./utils/redux/usersSlice";
import communitiesReducer from "./utils/redux/communitiesSlice";
import postsReducer from "./utils/redux/postsSlice";
import jobsReducer from "./utils/redux/jobsSlice";
import RentalsReducer from "./utils/redux/rentalsSlice";
import BusinessReducer from "./utils/redux/businessSlice";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, adminReducer);

export const store = configureStore({
  reducer: {
    admin: persistedReducer,
    users: usersReducer,
    communities: communitiesReducer,
    posts: postsReducer,
    jobs: jobsReducer,
    rentals: RentalsReducer,
    business: BusinessReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
