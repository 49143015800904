import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { signIn } from "../utils/redux/adminSlice";
import { AddUserPersistance } from "../utils/persistance";
import { errorLogger } from "../utils/helpers/errorLogger";
import { useToasts } from "react-toast-notifications";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

const SignInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginDetails.email,
        loginDetails.password
      );
      const user = userCredential.user;
      const uid = user.uid;
      const res = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}admin/login`,
        {
          email: loginDetails.email,
          uid: uid
        }
      );
      dispatch(
        signIn({
          userDetails: { ...res?.data?.user },
          accessToken: res?.data?.accessToken
        })
      );
      navigate("/");
      AddUserPersistance(res?.data);
    } catch (error) {
      errorLogger(error, addToast);
    }
  };

  const handleChange = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Heading>Login to Your Account</Heading>
      <Container>
        <InputWrapper>
          <Label>Enter Your Email :</Label>
          <TextField
            type="email"
            name="email"
            placeholder="youremail@gmail.com"
            value={loginDetails.email}
            onChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Password :</Label>
          <TextField
            type="password"
            name="password"
            placeholder="********"
            onChange={handleChange}
            value={loginDetails.password}
          />
        </InputWrapper>
        <Button fullWidth variant="contained" type="submit">
          sign In
        </Button>
      </Container>
    </form>
  );
};

export default SignInForm;

const Heading = styled.h1`
  text-align: center;
`;

const Container = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  margin: 3rem auto;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 400px;
`;

const Label = styled.label`
  font-size: 1.25rem;
  font-weight: 500;
`;
