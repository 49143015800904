import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { postsInitialState } from "./states";

const initialState = postsInitialState;

export const fetchPostsAsync = createAsyncThunk(
  "posts/Async",
  async (input, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { getState, rejectWithValue } = thunkApi;
    const { admin, posts } = getState();

    const config = {
      headers: {
        authorization: `Bearer ${admin.accessToken}`
      }
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}admin/getAllPosts?startTime=${
          posts?.filters.fromDate
        }&endTime=${posts?.filters?.toDate}&approved=${
          posts?.filters?.approved != null ? posts?.filters?.approved : ""
        }&reported=${posts?.filters?.reported}&index=${posts.index}&limit=${
          posts.limit
        }`,
        config
      );
      if (successCallback && typeof successCallback === "function") {
        successCallback();
      }
      return {
        posts: response?.data?.posts,
        totalCount: response?.data?.totalPosts
      };
    } catch (error) {
      errorCallback(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const PostsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setToDate: (state, action) => {
      state.filters.toDate = action.payload;
    },
    setFromDate: (state, action) => {
      state.filters.fromDate = action.payload;
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    approvePost: (state, action) => {
      const { id, newData } = action.payload;
      const index = state.value.findIndex((post) => post._id === id);
      if (index !== -1) {
        state.value[index] = {
          ...state.value[index],
          ...newData
        };
      }
    },
    deletePost: (state, action) => {
      const { id } = action.payload;
      const index = state.value.findIndex((post) => post._id === id);
      if (index !== -1) {
        state.value.splice(index, 1);
      }
    },
    setApproved: (state, action) => {
      state.filters.approved = action.payload;
    },
    setReported: (state) => {
      state.filters.reported = true;
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPostsAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPostsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action?.payload.posts;
      state.totalCount = action?.payload.totalCount;
    });
    builder.addCase(fetchPostsAsync.rejected, (state) => {
      state.isLoading = true;
    });
  }
});

export const {
  setFromDate,
  setToDate,
  clearDates,
  setIndex,
  setLimit,
  approvePost,
  deletePost,
  setApproved,
  setReported,
  clearFilters
} = PostsSlice.actions;

export default PostsSlice.reducer;
