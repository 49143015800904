import { lazy } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import SignInForm from "./pages/SignIn";
import ProtectedRouteWithSuspense from "./ProtectedRouteWithSuspense";

//Code splitting using lazy and suspense fallback for performance improvement
const DashbordPage = lazy(() => import("./pages/dashboard"));
const ProtectedRoute = lazy(() => import("./utils/ProtectedRoute"));
const RootLayout = lazy(() => import("./layout/rootLayout"));
const LogoOnlyLayout = lazy(() => import("./layout/logoOnlyLayout"));
const PostsPage = lazy(() => import("./pages/Posts"));
const UsersPage = lazy(() => import("./pages/Users"));
const CommunitiesPage = lazy(() => import("./pages/Communities"));
const HelpsPage = lazy(() => import("./pages/Help"));
const JobsPage = lazy(() => import("./pages/JobsPage"));
const RentalsPage = lazy(() => import("./pages/Rentals"));
const BusinessPage = lazy(() => import("./pages/Business"));
const GalleryPage = lazy(() => import("./pages/Gallery"));
const ProductsPage = lazy(() => import("./pages/BusinessProducts"));
const ReportedCommentsPage = lazy(() => import("./pages/ReportedComments"));

const DashboardPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <DashbordPage />
  </ProtectedRouteWithSuspense>
);

const UsersPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <UsersPage />
  </ProtectedRouteWithSuspense>
);

const GalleryPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <GalleryPage />
  </ProtectedRouteWithSuspense>
);

const PostsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <PostsPage />
  </ProtectedRouteWithSuspense>
);

const CommunitiesPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <CommunitiesPage />
  </ProtectedRouteWithSuspense>
);

const JobsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <JobsPage />
  </ProtectedRouteWithSuspense>
);

const RentalsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <RentalsPage />
  </ProtectedRouteWithSuspense>
);

const HelpPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <HelpsPage />
  </ProtectedRouteWithSuspense>
);

const BusinessPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <BusinessPage />
  </ProtectedRouteWithSuspense>
);

const ProductsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <ProductsPage />
  </ProtectedRouteWithSuspense>
);

const ReportedCommentsPageWithFallback = () => (
  <ProtectedRouteWithSuspense>
    <ReportedCommentsPage />
  </ProtectedRouteWithSuspense>
);

const PageRouter = () => {
  const admin = useSelector((state) => state.admin);
  const isAdmin = window.localStorage.getItem("redux-persist");
  // const isLoggedOut = admin?.accessToken && isTokenExpired(admin.accessToken);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path=""
          element={
            <ProtectedRoute>
              <RootLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<DashboardPageWithFallback />} />
          <Route path="posts" element={<PostsPageWithFallback />} />
          <Route path="users" element={<UsersPageWithFallback />} />
          <Route path="usersgallery" element={<GalleryPageWithFallback />} />
          <Route path="communities" element={<CommunitiesPageWithFallback />} />
          <Route path="jobs" element={<JobsPageWithFallback />} />
          <Route path="rentals" element={<RentalsPageWithFallback />} />
          <Route path="help" element={<HelpPageWithFallback />} />
          <Route path="business" element={<BusinessPageWithFallback />} />
          <Route
            path="reportedcomments"
            element={<ReportedCommentsPageWithFallback />}
          />
          <Route
            path="/business/:id/products"
            element={<ProductsPageWithFallback />}
          />
        </Route>
        <Route path="" element={<LogoOnlyLayout />}>
          <Route
            path="signin"
            element={
              !admin?.isLoggedIn || isEmpty(admin?.userDetails) || !isAdmin ? (
                <SignInForm />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default PageRouter;
