export const errorToastOptions = { appearance: "error", autoDismiss: true };

export const errorLogger = (error, addToast, callback = () => {}) => {
  if (error?.response?.data?.resultMessage) {
    addToast(`${error?.response?.data?.resultMessage?.en}`, errorToastOptions);
  } else {
    addToast(`${error.message}`, errorToastOptions);
  }
  callback();
};
