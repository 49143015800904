import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { rentalsInitialState } from "./states";
import axios from "axios";

const initialState = rentalsInitialState;

export const fetchRentalsAsync = createAsyncThunk(
  "rentals/Async",
  async (input, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { getState, rejectWithValue } = thunkApi;
    const { admin, rentals } = getState();

    const config = {
      headers: {
        authorization: `Bearer ${admin.accessToken}`
      }
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}admin/getAllRentals?startDate=${rentals?.filters?.fromDate}&endDate=${rentals?.filters?.toDate}&index=${rentals?.index}&limit=${rentals?.limit}&search=${rentals.search}`,
        config
      );
      if (successCallback && typeof successCallback === "function") {
        successCallback();
      }
      return {
        rentals: response?.data?.content,
        totalCount: response?.data?.totalRentals
      };
    } catch (error) {
      errorCallback(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const RentalsSlice = createSlice({
  name: "rentals",
  initialState,
  reducers: {
    verified: (state, action) => {
      const { id, newData } = action.payload;
      const index = state.value.findIndex((rental) => rental._id === id);
      if (index !== -1) {
        state.value[index] = {
          ...state.value[index],
          ...newData
        };
      }
    },
    setToDate: (state, action) => {
      state.filters.toDate = action.payload;
    },
    setFromDate: (state, action) => {
      state.filters.fromDate = action.payload;
    },
    clearFilter: (state) => {
      state.filters.fromDate = "";
      state.filters.toDate = "";
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSearchText: (state, action) => {
      state.search = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRentalsAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRentalsAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload.rentals;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchRentalsAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  }
});

export default RentalsSlice.reducer;

export const {
  verified,
  setToDate,
  setFromDate,
  clearFilter,
  setIndex,
  setLimit,
  setSearchText
} = RentalsSlice.actions;
