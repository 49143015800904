import { usersInitialState } from "./states";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = usersInitialState;

export const FetchUsersAsync = createAsyncThunk(
  "users/Async",
  async (input, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { getState, rejectWithValue } = thunkApi;
    const { admin, users } = getState();
    const config = {
      headers: {
        authorization: `Bearer ${admin.accessToken}`
      }
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}admin/getAllUsers?index=${users.index}&limit=${users.limit}&search=${users.search}&blocked=${users.filters.approved}&deleted=${users.filters.deleted}`,
        config
      );
      successCallback();
      return {
        users: response.data.users,
        totalCount: response.data.totalUsers
      };
    } catch (error) {
      errorCallback(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSearchText: (state, action) => {
      state.search = action.payload;
    },
    approvedUsers: (state, action) => {
      state.filters.approved = action.payload;
      state.filters.deleted = null;
    },
    deletedUsers: (state, action) => {
      state.filters.deleted = action.payload;
      state.filters.approved = null;
    },
    clearFilters: (state, action) => {
      state.filters = {
        approved: null,
        deleted: null
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(FetchUsersAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FetchUsersAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action?.payload?.users;
      state.totalCount = action?.payload?.totalCount;
    });
    builder.addCase(FetchUsersAsync.rejected, (state) => {
      state.isLoading = true;
    });
  }
});

export default userSlice.reducer;
export const {
  setIndex,
  setLimit,
  setSearchText,
  approvedUsers,
  deletedUsers,
  clearFilters
} = userSlice.actions;
