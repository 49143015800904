import "./App.css";
import { ToastProvider } from "react-toast-notifications";
import PageRouter from "./pageRouter";

function App() {
  return (
    <div className='App'>
      <ToastProvider placement='top-right'>
        <PageRouter />
      </ToastProvider>
    </div>
  );
}

export default App;
