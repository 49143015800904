import { createSlice } from "@reduxjs/toolkit";
import { adminInitialState } from "./states";

const initialState = adminInitialState;

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.isLoggedIn = true;
      state.accessToken = action?.payload?.accessToken;
      state.userDetails = action?.payload?.userDetails;
    },
    signOut: (state, action) => {
      state.isLoggedIn = false;
      state.accessToken = null;
      state.userDetails = null;
    },
  },
});

export const { signIn, signOut } = adminSlice.actions;

export default adminSlice.reducer;
