import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { businessInitialState } from "./states";
import axios from "axios";

const initialState = businessInitialState;

export const fetchBusinessAsync = createAsyncThunk(
  "business/Async",
  async (input, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { getState, rejectWithValue } = thunkApi;
    const { admin, business } = getState();

    const config = {
      headers: {
        authorization: `Bearer ${admin.accessToken}`
      }
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}admin/getAllCompanies?index=${business.index}&limit=${business.limit}`,
        config
      );
      if (successCallback && typeof successCallback === "function") {
        successCallback();
      }
      return {
        businesses: response.data.content,
        totalCount: response.data.totalBizz
      };
    } catch (error) {
      errorCallback(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const BusinessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    verified: (state, action) => {
      const { id, newData } = action.payload;
      const index = state.value.findIndex((bizz) => bizz._id === id);
      if (index !== -1) {
        state.value[index] = {
          ...state.value[index],
          ...newData
        };
      }
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBusinessAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action.payload.businesses;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchBusinessAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  }
});

export default BusinessSlice.reducer;
export const { verified, setIndex, setLimit } = BusinessSlice.actions;
