import { communitiesInitialState } from "./states";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = communitiesInitialState;

export const fetchCommunitiesAsync = createAsyncThunk(
  "communities/Async",
  async (input, thunkApi) => {
    const { successCallback, errorCallback } = input;
    const { getState, rejectWithValue } = thunkApi;
    const { admin, communities } = getState();
    const config = {
      headers: {
        authorization: `Bearer ${admin.accessToken}`
      }
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}admin/getAllCommunities?index=${communities.index}&limit=${communities.limit}&search=${communities.search}`,
        config
      );
      successCallback();
      return {
        communities: response.data.communities,
        totalCount: response.data.totalCommunities
      };
    } catch (error) {
      errorCallback(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const communitiesSlice = createSlice({
  name: "communities",
  initialState,
  reducers: {
    activated: (state, action) => {
      const { id, newData } = action.payload;
      const index = state.value.findIndex((comm) => comm._id === id);
      if (index !== -1) {
        state.value[index] = {
          ...state.value[index],
          ...newData
        };
      }
    },
    verifier: (state, action) => {
      const { id, newData } = action.payload;
      const index = state.value.findIndex((comm) => comm._id === id);
      if (index !== -1) {
        state.value[index] = {
          ...state.value[index],
          ...newData
        };
      }
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSearchText: (state, action) => {
      state.search = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommunitiesAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCommunitiesAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.value = action?.payload?.communities;
      state.totalCount = action?.payload?.totalCount;
    });
    builder.addCase(fetchCommunitiesAsync.rejected, (state) => {
      state.isLoading = true;
    });
  }
});

export default communitiesSlice.reducer;
export const { verifier, activated, setIndex, setLimit, setSearchText } =
  communitiesSlice.actions;
